// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@tailwind base;
@tailwind components;
@tailwind utilities;

// Plus imports for other components in your app.
$custom-typography: mat.define-typography-config(
    $font-family: 'Roboto Condensed',
);
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$dark-primary-text: var(--color-text-dark);
$light-primary-text: var(--color-text-light);
$primary: var(--color-primary);
$bg-white: var(--color-background-white);
$bg-light: var(--color-background-light);
$bg-medium: var(--color-background-medium);
$bg-dark: var(--color-background-dark);
$accent-primary: var(--color-accent-primary);
$border-light: var(--color-border-color-light);
$validation-error-text: var(--color-validation-error);

$cyan-palette: (
    50: $bg-medium,
    100: $bg-medium,
    200: $bg-dark,
    300: $bg-dark,
    400: $primary,
    500: $primary,
    600: $primary,
    700: $primary,
    800: $primary,
    900: $primary,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);

$orange-palette: (
    50: $accent-primary,
    100: $accent-primary,
    200: $accent-primary,
    300: $accent-primary,
    400: $accent-primary,
    500: $accent-primary,
    600: $accent-primary,
    700: $accent-primary,
    800: $accent-primary,
    900: $accent-primary,
    contrast: (
        50: $light-primary-text,
        100: $light-primary-text,
        200: $light-primary-text,
        300: $light-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);

$red-palette: (
    50: #ffebee,
    100: #fecdd2,
    200: #ed9999,
    300: #c32828,
    400: #b41d1c,
    500: #b41d1c,
    600: #b41d1c,
    700: #b41d1c,
    800: #b41d1c,
    900: #b41d1c,
    contrast: (
        50: $dark-primary-text,
        100: $dark-primary-text,
        200: $dark-primary-text,
        300: $dark-primary-text,
        400: $light-primary-text,
        500: $light-primary-text,
        600: $light-primary-text,
        700: $light-primary-text,
        800: $light-primary-text,
        900: $light-primary-text,
    ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$ffa-frontend-primary: mat.define-palette($cyan-palette);
$ffa-frontend-accent: mat.define-palette($orange-palette);

// The warn palette is optional (defaults to red).
$ffa-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$ffa-frontend-theme: mat.define-light-theme(
    (
        color: (
            primary: $ffa-frontend-primary,
            accent: $ffa-frontend-accent,
            warn: $ffa-frontend-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($ffa-frontend-theme);

/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'Roboto Condensed';
    src: url('assets/RobotoCondensed-Regular.ttf');
}

@font-face {
    font-family: 'Roboto Condensed-SemiBold';
    src: url('assets/RobotoCondensed-SemiBold.ttf');
}

@font-face {
    font-family: 'Roboto Condensed-Bold';
    src: url('assets/RobotoCondensed-Bold.ttf');
}

@layer base {
    html {
        font-family: 'Roboto Condensed', system-ui, sans-serif;
    }
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: 'Roboto Condensed', 'Helvetica Neue', sans-serif;
    position: relative;
}

h1 {
    @apply text-h1;
    color: $accent-primary;
}

h2 {
    line-height: 1.3;
}

p {
    color: $dark-primary-text;
    a {
        color: $primary;
    }
}

//Material UI overwrites. Default settings for all material components:

.mat-mdc-menu-panel {
    border: 0.0625rem solid $border-light;
    border-radius: 0 !important;
    max-width: unset !important;
    .mat-mdc-menu-content {
        padding: 0;
    }
}

.mat-sort-header-container {
    .mat-sort-header-arrow {
        display: none;
    }
}

.antrag-creation__accordion {
    .mat-expansion-panel-body {
        @apply bg-background-light;
        padding: 1.625rem 0.625rem;
        //Material UI overwrites no. These all need a combinator before them to specify the component they are changed for.
    }
}

.unternehmen-selection__menu__panel {
    border-color: $dark-primary-text;
    border-top: none;
    width: 16rem;
}
.data-table {
    &__postkorb,
    &__history-tab,
    &__nebenantrag,
    &__all-unternehmen-nutzer,
    &__all-antraege,
    &__right-management-allantrag,
    &__right-management-foerderantrag,
    &__dokumente-view-tab {
        span {
            display: flex;
            align-items: center;
        }

        .mat-mdc-header-row {
            @apply bg-primary;
            height: 3.5rem;
            .mat-column-actions {
                cursor: default;
            }
        }

        .mat-mdc-row {
            border-bottom: 0.0625rem solid $bg-dark;
            height: 1.625rem;
            cursor: pointer;
            &:hover {
                background-color: $bg-medium;
            }
        }

        .mat-mdc-header-cell {
            @apply text-text-light text-tableHeader;
            border: none;
            padding: 0 0.5rem;
            cursor: pointer;
        }

        .mat-mdc-cell {
            @apply text-text-dark text-small;
            border: none;
            padding: 0 0.5rem;
            word-break: break-word;
            overflow-wrap: break-word;
        }

        .mat-column-actions {
            width: 5rem;
        }
    }

    &__all-antraege {
        .mat-column-foerderart-bezeichnung {
            width: 12%;
            padding-left: 1rem;
            overflow-wrap: break-word;
        }

        .mat-column-antragsnummer,
        .mat-column-foerdernummer {
            width: 10%;
            overflow-wrap: break-word;
        }

        .mat-column-projektname {
            width: calc(42% - 6.25rem);
            overflow-wrap: break-word;
        }

        .mat-column-aktualisiertAm,
        .mat-column-statusString {
            width: 13%;
            overflow-wrap: break-word;
        }

        @media only screen and (max-width: 1400px) {
            .mat-column-statusString,
            .mat-column-foerderart-bezeichnung,
            .mat-column-foerdernummer,
            .mat-column-antragsnummer,
            .mat-column-aktualisiertAm {
                width: 9rem;
            }

            .mat-column-projektname {
                width: 15rem;
            }
        }
    }
    &__right-management-allantrag,
    &__right-management-foerderantrag,
    &__all-unternehmen-nutzer {
        .mat-column-email {
            width: calc(42% - 6.25rem);
            overflow-wrap: break-word;
        }
        .mat-column-statusString {
            width: 5%;
        }
        .mat-column-nachname,
        .mat-column-name,
        .mat-column-role {
            width: 10%;
            overflow-wrap: break-word;
        }

        @media only screen and (max-width: 1400px) {
            .mat-column-statusString {
                width: 5rem;
            }
            .mat-column-nachname,
            .mat-column-name,
            .mat-column-role {
                width: 7rem;
            }

            .mat-column-email {
                width: 15rem;
            }
        }
    }
    &__right-management-allantrag,
    &__right-management-foerderantrag {
        &--selected {
            @apply bg-background-medium;
        }
    }
    &__nebenantrag {
        .mat-mdc-row {
            cursor: unset;
            &:hover {
                background-color: unset;
            }
        }

        .mat-column-antragsnummer,
        .mat-column-aktualisiertAm,
        .mat-column-statusString {
            width: 30%;
            overflow-wrap: break-word;
        }
        @media screen and (max-width: 1400px) {
            .mat-column-statusString,
            .mat-column-antragsnummer,
            .mat-column-aktualisiertAm {
                width: 9rem;
            }
        }
    }

    &__history-tab {
        .mat-mdc-row {
            cursor: unset;
            &:hover {
                background-color: unset;
            }
        }

        .mat-column-kommentar {
            width: 55%;
            overflow-wrap: break-word;
        }
        .mat-column-zeitstempel {
            width: 10%;
        }
        .mat-column-anderungsart {
            width: 15%;
        }
        .mat-column-ansprechpartner {
            width: 20%;
        }
        @media screen and (max-width: 1400px) {
            .mat-column-zeitstempel {
                width: 9rem;
            }
            .mat-column-anderungsart,
            .mat-column-ansprechpartner {
                width: 13rem;
            }
            .mat-column-kommentar {
                width: 31rem;
            }
        }
    }

    &__postkorb {
        &__table-row__ungelesen {
            @apply text-strong #{!important};
        }
        .mat-column-betreff {
            width: 40%;
            overflow-wrap: break-word;
        }

        .mat-column-foerderantrag-projektname {
            width: 30%;
            overflow-wrap: break-word;
        }

        .mat-column-mitAnhang {
            text-align: center;
            width: 5%;
        }
        .mat-column-foerderantrag-antragsnummer,
        .mat-column-zustellzeitpunkt {
            width: 10%;
            overflow-wrap: break-word;
        }
        @media screen and (max-width: 1400px) {
            .mat-column-mitAnhang {
                width: 9rem;
            }
            .mat-column-foerderantrag-antragsnummer,
            .mat-column-zustellzeitpunkt {
                width: 13rem;
            }
            .mat-column-foerderantrag-projektname,
            .mat-column-betreff {
                width: 20rem;
            }
        }
    }

    &__paginator {
        .mat-mdc-select {
            color: $dark-primary-text;
        }
        &__panel {
            .mat-mdc-option {
                color: $dark-primary-text;
            }
        }
    }
    &__right-management-allantrag,
    &__right-management-foerderantrag {
        .mdc-checkbox__checkmark {
            display: none; /* Hide the tick mark */
        }
    }

    &__dokumente-view-tab {
        &__table-row__ungelesen {
            @apply text-strong #{!important};
        }
        .mat-mdc-row {
            cursor: unset;
            &:hover {
                background-color: unset;
            }
        }

        .mat-column-dokument-tagType,
        .mat-column-antrag-antragsnummer,
        .mat-column-dokument-erstelltAm {
            width: 15%;
            padding-left: 1rem;
            overflow-wrap: break-word;
        }

        .mat-column-dokument-name {
            width: calc(55% - 6.25rem);
            overflow-wrap: break-word;
        }

        @media only screen and (max-width: 1400px) {
            .mat-column-dokument-erstelltAm,
            .mat-column-antrag-antragsnummer {
                width: 9rem;
            }

            .mat-column-dokument-name {
                width: 15rem;
            }
        }
    }
}

.search-form {
    .mdc-text-field--filled {
        border-radius: unset;
        &:not(.mdc-text-field--disabled) {
            @apply bg-background-medium;
        }
    }
    .mat-mdc-form-field-icon-suffix {
        padding-right: 1rem;
    }
    .mat-mdc-form-field-icon-prefix {
        width: 2.5rem;
        padding: 0 0.5rem;
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }
}

.quick-filter__menu__panel {
    .mat-mdc-menu-content {
        display: flex;
        flex-direction: column;
    }
    .mat-mdc-menu-item {
        padding: 0;
    }
    .mdc-text-field--filled {
        border-radius: unset;
        &:not(.mdc-text-field--disabled) {
            background-color: $light-primary-text;
        }
    }
    .mat-mdc-form-field-icon-suffix {
        padding-right: 1rem;
    }
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }
    .mat-mdc-menu-ripple {
        display: none;
    }
}

.nebenantrag-detail-page--top-section--top-half--button-width,
.nebenantraege-tab__art__button-card--button-width,
.antrag-details-tab--bottom-section--button-width {
    text-align: center;
    width: 14.375rem;
}

.antrag-details--bottom-section__tab-group {
    .mat-mdc-tab-labels {
        height: 3.25rem !important;
    }
    .mat-mdc-tab {
        height: unset !important;
        padding: unset;
        width: 20%;
        border: 1px solid $dark-primary-text;
        border-right: none;
    }
    .mdc-tab__content {
        width: 100%;
    }
    .mat-mdc-tab:first-child {
        border-left: none;
    }
    .mdc-tab__text-label {
        @apply text-label__regular;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .antrag-details--bottom-section__tab-group {
            &__active-indicator {
                height: 0.1875rem;
                width: 100%;
            }

            &__spacer-bottom {
                height: 0.1875rem;
                width: 100%;
            }
        }
    }
    .mdc-tab--active {
        border-top: none;
        border-bottom: none;
        .mdc-tab__text-label {
            @apply text-label;
            .antrag-details--bottom-section__tab-group {
                &__active-indicator {
                    background-color: $primary;
                    height: 0.25rem;
                }
                &__spacer-bottom {
                    height: 0.25rem;
                }
            }
        }
    }

    .mdc-tab-indicator {
        display: none;
    }

    .mat-mdc-tab-body-content {
        min-height: 40vh;
    }
}

// Amplify component customization

.amplify-tabs__item {
    border-bottom: 1px solid var(--color-border-color-dark);
    color: var(--color-text-dark);
    --amplify-components-tabs-item-border-color: transparent;
    &--active {
        border-bottom: unset;
        color: var(--amplify-components-tabs-item-active-color);
        &:first-child {
            border-right: 1px solid var(--color-border-color-dark);
        }
        &:last-child {
            border-left: 1px solid var(--color-border-color-dark);
        }
    }
}

amplify-error {
    svg,
    div {
        color: $validation-error-text !important;
    }
    button {
        display: none !important;
    }
}

.amplify-tabs__list {
    --amplify-components-tabs-border-color: transparent;
}

.amplify-button--primary {
    background: $accent-primary;
    border-radius: 0;
}

.amplify-label {
    color: $dark-primary-text;
}

.amplify-input {
    color: $dark-primary-text;
    border-radius: 0;
}

.amplify-select {
    color: $dark-primary-text;
    border-radius: 0;
    option {
        @apply text-text-dark;
    }
}

.amplify-heading {
    text-align: center;
    color: $accent-primary;
    padding: var(--amplify-space-xl) 0 0 var(--amplify-space-xl);
    font-size: 2.125rem;
}

.amplify-hint {
    font-size: 0.8rem;
    color: $dark-primary-text;
    text-decoration: underline;
}

.amplify-error {
    &-container {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 1rem 0;
        background-color: var(--amplify-components-alert-error-background-color);
        font-size: 1rem;
        color: $validation-error-text;
    }
}

.amplify-link {
    color: $primary;
    text-decoration: underline;
}

.amplify-text {
    color: $dark-primary-text;
    &--error {
        color: $validation-error-text;
    }
}

.amplify-field__error-message {
    color: $validation-error-text !important;
}

[data-amplify-authenticator] [data-amplify-router] {
    --amplify-components-authenticator-router-border-color: var(--color-border-color-dark);
}

app-amplify-selector,
app-amplify-password-field,
app-amplify-phone-number-field,
amplify-password-field,
amplify-text-field {
    .amplify-icon {
        color: $dark-primary-text;
    }

    input {
        border-color: unset !important;
        &:focus {
            box-shadow: 0px 0px 0px 2px $primary !important;
        }
        &:focus-visible {
            outline: none !important;
        }
    }

    select {
        border-color: unset !important;
        &:focus {
            box-shadow: 0px 0px 0px 2px $primary !important;
        }
        &:focus-visible {
            outline: none !important;
        }
    }

    button {
        border-color: var(--color-border-color-dark) !important;
        border-left: unset !important;
        &:focus {
            box-shadow: 0px 0px 0px 2px $primary !important;
        }
    }
}

.snack-bar-panel {
    &-default {
        margin-top: 7rem !important;
        .mdc-snackbar__surface {
            max-width: unset !important;
        }
    }
    &-success {
        .mdc-snackbar__surface {
            @apply bg-background-medium #{!important};
        }
    }
    &-error {
        .mdc-snackbar__surface {
            @apply bg-background-error #{!important};
        }
    }
}

// Tippy.js style & theme imports

@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/dist/svg-arrow.css';
/** Themes */
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/material.css';
@import 'tippy.js/themes/translucent.css';

.tippy__hint {
    white-space: pre-line;
}

.tippy-box[data-theme~='light-border'] {
    background-color: $bg-medium;
    color: $dark-primary-text;
}
.tippy-box[data-theme~='light-border'][data-placement^='bottom'] > .tippy-arrow::before {
    border-bottom-color: $bg-medium;
}

// Remove Chrome automaticaly added clear input icon
input::-webkit-search-cancel-button,
input[type='search']::-webkit-search-cancel-button,
input[type='text']::-webkit-search-decoration,
input[type='text']::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
}

.right-management-allantrag,
.right-management-foerderantrag {
    .data-table__wrapper {
        min-height: 6rem;
    }
}
.right-management-allantrag .right-management-allantrag__search-box,
.right-management-foerderantrag .right-management-foerderantrag__search-box {
    display: flex;
    justify-content: flex-end;
    padding: 0.6rem 0rem;

    .search-form .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        --tw-bg-opacity: 0;
        background-color: rgb(229 246 246 / var(--tw-bg-opacity));
    }
}

.dialog-actions {
    .dialog-button {
        width: 14rem;
    }

    .liste-button {
        .mat-mdc-raised-button:not(:disabled) {
            color: var(--color-primary) !important;
            background-color: #ffffff !important;
            border: 0.149rem solid var(--color-primary);
        }
    }
}
.progress-bar {
    .mdc-linear-progress__buffer-bar {
        @apply bg-background-medium;
    }
}

.rolle-management-dialog-container,
.right-management-foerderbereich__container__buttons {
    .mat-mdc-raised-button:not(:disabled) {
        color: var(--color-primary) !important;
        background-color: #ffffff;
        border: 0.149rem solid var(--color-primary);
    }

    .selected {
        .mat-mdc-raised-button:not(:disabled) {
            background-color: var(--color-primary) !important;
            color: #ffffff !important;
        }
    }
    .dialog-button {
        border: 0.149rem solid $primary;
    }
}

.rolle-management-dialog-container {
    .dialog-button {
        width: 50vw;
    }
}

.right-management-foerderbereich__container {
    &__search-box {
        display: flex;
        justify-content: flex-end;
        padding: 0.6rem 0rem;

        .search-form .mdc-text-field--filled:not(.mdc-text-field--disabled) {
            --tw-bg-opacity: 0;
            background-color: rgb(229 246 246 / var(--tw-bg-opacity));
        }
    }

    &__buttons {
        &__dialog-button {
            width: 12rem;
        }
    }
}

.postkorb-nachricht-dialog {
    h1 {
        @apply text-h2;
        text-align: left;
    }
}

.mdc-checkbox {
    padding: calc((var(--mdc-checkbox-state-layer-size) - 40px) / 2);

    .mdc-checkbox__background {
        top: calc((var(--mdc-checkbox-state-layer-size) - 41px) / 2);
        left: calc((var(--mdc-checkbox-state-layer-size) - 27px) / 2);
        border-radius: 0;
        border: 0.149rem solid var(--color-accent-primary) !important;
    }
}

// Toggle Button - Benutzer hinzufügen
.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    background-color: var(--color-accent-primary) !important;
    color: var(--color-text-light);
    font-size: 0.875rem;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    font-size: 0.875rem !important;
    color: var(--color-primary) !important;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked .mat-button-toggle-label-content {
    color: var(--color-text-light) !important;
}

.impressum__container {
    p {
        padding: 0.5rem 0;
    }
    .main-content {
        float: right;
        width: 59.25926%;
        margin: 1.5em 7.40741% 3.75em 0;
    }
    @media screen and (max-width: 1024px) {
        .main-content {
            float: none;
            width: auto;
            margin-right: 5.55556%;
            margin-left: 5.55556%;
        }
    }
    .centered-wrapper {
        zoom: 1;
        position: relative;
        clear: both;
        background: 50% 50% no-repeat;
        background-size: cover;
    }
    .centered-wrapper:before {
        content: '';
        display: table;
    }
    .centered-wrapper:after {
        content: '';
        display: table;
        clear: both;
    }

    .centered-wrapper-inner {
        max-width: 1185.1851851852px;
        margin-right: auto;
        margin-left: auto;
    }
    @media screen and (max-width: 1280px) {
        .centered-wrapper-inner {
            max-width: none;
            margin-right: 3.7037%;
            margin-right: var(--page-padding-right);
            margin-left: 3.7037%;
            margin-left: var(--page-padding-left);
        }
    }
    @media screen and (max-width: 1024px) {
        .centered-wrapper-inner {
            margin-right: 5.55556%;
            margin-right: var(--page-padding-right);
            margin-left: 5.55556%;
            margin-left: var(--page-padding-left);
        }
    }
    .rs-columns {
        clear: both;
        display: inline-block;
        vertical-align: top;
        width: 100%;
    }
    .rs-column {
        float: left;
        width: 100%;
        margin-top: 6.25%;
        margin-right: 6.25%;
    }
    .rs-column.-large-first {
        clear: left;
    }
    .rs-column.-large-last {
        margin-right: -5px;
    }
    .rs-column.-large-first-row {
        margin-top: 0;
    }
    .rs-column.-large-col-1-1 {
        width: 100%;
    }
    .rs-column.-large-col-2-1 {
        width: 46.875%;
    }
    .rs-column.-large-col-2-2 {
        width: 100%;
    }
    .rs-column.-large-col-3-1 {
        width: 29.16667%;
    }
    .rs-column.-large-col-3-2 {
        width: 64.58333%;
    }
    .rs-column.-large-col-3-3 {
        width: 100%;
    }
    .rs-column.-large-col-4-1 {
        width: 20.3125%;
    }
    .rs-column.-large-col-4-2 {
        width: 46.875%;
    }
    .rs-column.-large-col-4-3 {
        width: 73.4375%;
    }
    .rs-column.-large-col-4-4 {
        width: 100%;
    }
    .rs-column.-large-col-5-1 {
        width: 15%;
    }
    .rs-column.-large-col-5-2 {
        width: 36.25%;
    }
    .rs-column.-large-col-5-3 {
        width: 57.5%;
    }
    .rs-column.-large-col-5-4 {
        width: 78.75%;
    }
    .rs-column.-large-col-5-5 {
        width: 100%;
    }
    .rs-column.-large-col-6-1 {
        width: 11.45833%;
    }
    .rs-column.-large-col-6-2 {
        width: 29.16667%;
    }
    .rs-column.-large-col-6-3 {
        width: 46.875%;
    }
    .rs-column.-large-col-6-4 {
        width: 64.58333%;
    }
    .rs-column.-large-col-6-5 {
        width: 82.29167%;
    }
    .rs-column.-large-col-6-6 {
        width: 100%;
    }
    .rs-column.-large-col-7-1 {
        width: 8.92857%;
    }
    .rs-column.-large-col-7-2 {
        width: 24.10714%;
    }
    .rs-column.-large-col-7-3 {
        width: 39.28571%;
    }
    .rs-column.-large-col-7-4 {
        width: 54.46429%;
    }
    .rs-column.-large-col-7-5 {
        width: 69.64286%;
    }
    .rs-column.-large-col-7-6 {
        width: 84.82143%;
    }
    .rs-column.-large-col-7-7 {
        width: 100%;
    }
}

//set the cursor option to the body and all children to overwrite later setting of the cursor
.progress-cursor,
.progress-cursor * {
    cursor: progress !important;
}

// Nutzer profile
.nutzer-profile__bottom-section {
    &--no-edit {
        .general-card {
            background-color: $bg-light;
        }
    }
    &__edit-actions__cancle {
        .mat-mdc-raised-button:not(:disabled) {
            color: var(--color-primary) !important;
            background-color: #ffffff;
            border: 0.149rem solid $primary;
        }
    }

    .nutzer-profile__bottom-section__nutzer-data .general-card,
    .nutzer-profile__bottom-section__nutzer-data .general-card__content,
    .nutzer-profile__bottom-section__contact-info .general-card,
    .nutzer-profile__bottom-section__contact-info .general-card__content {
        width: 100%;
        display: flex;
        height: 100%;
    }

    .nutzer-profile__bottom-section__nutzer-data .general-card__content {
        gap: 1rem;
    }

    .nutzer-profile__bottom-section__form {
        .mat-mdc-form-field {
            width: 100%;
        }

        .mdc-text-field {
            padding: 0 0.5rem;
        }

        .mat-mdc-text-field-wrapper {
            padding: 0;
        }

        .mdc-text-field--filled {
            border-radius: unset;
            &:not(.mdc-text-field--disabled) {
                @apply bg-background-medium;
            }
        }

        .mat-mdc-form-field-infix {
            padding: unset !important;
            min-height: unset !important;
        }

        .mat-mdc-select,
        .mat-mdc-input-element {
            min-height: 1.875rem;
            @apply text-label__regular text-text-dark;
        }

        .mdc-line-ripple::after {
            display: none;
        }
    }
}

.change-password-dialog {
    .mat-mdc-form-field {
        width: 100%;
    }

    .mdc-text-field {
        @apply text-text-dark;
        padding: 0 0.5rem;
        border: 2px solid $primary;
    }

    .mdc-text-field--filled {
        border-radius: unset;
        &:not(.mdc-text-field--disabled) {
            @apply bg-background-white;
        }
        .mdc-floating-label--float-above {
            transform: translateY(-120%) scale(0.75);
        }
    }

    .mat-mdc-form-field-infix {
        padding: unset !important;
        min-height: unset !important;
    }

    .mat-mdc-input-element {
        min-height: 1.875rem;
        padding-top: 1.25rem;
        @apply text-label__regular text-text-dark #{!important};
    }
    .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label,
    .mat-mdc-form-field-error {
        caret-color: $validation-error-text !important;
        color: $validation-error-text !important;
    }
    .mdc-text-field--filled.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
        border: 2px solid $validation-error-text;
    }

    .mdc-line-ripple::after {
        display: none;
    }
}

.snack-bar-panel-unauthorized {
    margin: 0;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;

    .mdc-snackbar__surface {
        --tw-bg-opacity: 1;
        background-color: rgb(229 246 246 / 1);
        max-width: unset;
    }
}

//Mat-Datepicker
.mat-calendar-body-cell-content {
    color: $dark-primary-text;
}
.mat-calendar-body-selected {
    color: $light-primary-text;
}
.mat-calendar-period-button {
    .mdc-button__label {
        color: $dark-primary-text;
    }
}
.ffa-maintenance-management--bottom-section__button {
    @apply text-label #{!important};
}

.date-time-input-group {
    .mat-mdc-form-field-subscript-wrapper {
        display: none !important;
    }
}


.csv-management--bottom-section--form {
    .mdc-list {
        padding: unset;
    }
    .mdc-list-item__primary-text {
        color: $dark-primary-text !important;
    }
}
